// import Style Files
@import "../../styles/vars";

.Popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out;
  }

  .content {
    background-color: #fff;
    width: 100%;
    padding: 16px;
    position: relative;
    z-index: 1;
    border-radius: 25px 25px 0 0;
    max-height: 380px;
    bottom: -380px;
    transition: all 0.3s ease-in-out;

    .head {
      position: relative;
      margin-top: 10px;

      .title {
        font-size: 16px;
        color: $text-color;
        font-weight: 700;
        text-align: center;
      }

      .close {
        position: absolute;
        color: $text-color;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:active {
          color: #ccc;
        }
      }
    }
  }

  &.opened {
    opacity: 1;
    z-index: 99;

    .content {
      bottom: 0;
    }
  }
}
