// import Style Files
@import "../../styles/vars";

.History {
  margin-top: 20px;
  position: relative;
  z-index: 6;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: $text-color;
      font-size: 13px;
      font-family: "Poppins";
      font-weight: 700;
    }

    .deleteAll {
      color: $error-color;
      font-size: 13px;
      font-family: "Poppins";
      font-weight: 400;
      cursor: pointer;

      &:active {
        color: #ccc;
      }
    }
  }

  .historyItems {
    height: 162px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .noHistory {
    color: #8f8f8f;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 100;
    text-align: center;
    margin-top: 40px;
  }
}

.HistoryItemWrapper {
  position: relative;

  .HistoryItem {
    background-color: #fff;
    padding: 13px 16px 15px;
    border-radius: 19px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    position: relative;
    z-index: 9;

    .cardContent {
      display: flex;
      align-items: center;
      gap: 11px;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 53px;

        span {
          font-size: 10px;
          bottom: 0;
          background-color: #fff;
          padding: 0 3px;
          border-radius: 3px;
          font-weight: 200;
          color: #b9b9b9;
          border: 1px solid #b9b9b9;
          margin-top: -14px;
        }
      }

      .phoneNumber {
        color: $text-color;
        font-size: 16px;
        font-family: "Poppins";
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 3px;

        span {
          &.date {
            color: #aeaeae;
            font-size: 13px;
            font-weight: 100;
          }
        }
      }
    }

    .openChat {
      color: #c9c9c9;
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 700;
      cursor: pointer;
      border: 3px solid #c9c9c9;
      border-radius: 20px;
      padding: 6px 10px;

      &:active {
        background-color: #c9c9c9;
        color: #fff;
      }
    }
  }

  .deleteButton {
    background-color: $error-color;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 200;
    cursor: pointer;
    color: #fff;
    border: none;
    width: 65px;
    height: 100%;
    border-radius: 19px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  @media (max-width: 480px) {
    .HistoryItem {
      touch-action: none;

      .phoneNumber {
        font-size: 14px !important;

        span {
          &.date {
            font-size: 11px !important;
          }
        }
      }
    }
  }
}

.deleteAll {
  .deleteAllIcon {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deleteAllText {
    margin-top: 20px;
    color: #afafaf;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 400;
    text-align: center;
  }

  .deleteAllButtons {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 13px;

    .deleteAllButton {
      background-color: $error-color;
      font-size: 16px;
      font-family: "Poppins";
      font-weight: 500;
      cursor: pointer;
      color: #fff;
      width: 100%;
      height: 46px;
      border-radius: 46px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        background-color: #cf3a42;
        color: #fff;
      }
    }

    .cancelButton {
      font-size: 16px;
      font-family: "Poppins";
      font-weight: 400;
      cursor: pointer;
      color: $error-color;
      width: 100%;
      height: 46px;
      border-radius: 46px;
      background: rgba(255, 75, 85, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        background-color: $error-color;
        color: #fff;
      }
    }
  }
}
