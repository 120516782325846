@import "../../../styles/vars";

.LeftSide {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.7s ease-in-out;

  // Main Content
  .mainContent {
    p {
      font-size: 33px;
      font-weight: 500;
      color: #4b4b4b;
      font-family: "Secular-One";
    }
  }

  // Download Buttons
  .downloadButtons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .downloadButton {
      position: relative;
      background-color: #fff;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #737373;
      font-family: "Secular-One";
      font-size: 28px;
      text-decoration: none;
      padding: 14px 0;
      transition: all 0.2s ease-in-out;

      .lottie {
        width: 120px;
        height: 120px;
        margin-left: -30px;
      }

      &.playStore {
        .lottie {
          svg {
            transform: scale(2) !important;
          }
        }
      }

      &.googleChrome {
        .lottie {
          svg {
            transform: scale(0.75) !important;
          }
        }
      }

      &.website {
        background-color: #f1f1f1;
        border: 8px solid #fff;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 36px;
        background-color: #3e8e8138;
        transition: all 0.4s ease-in-out;
        bottom: 18px;
      }

      &::before {
        right: 26px;
      }

      &::after {
        right: 46px;
      }

      &:hover {
        box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.068);
      }
    }
  }

  @media (min-width: 1600px) {
    // Top Bar
    .topBar {
      img {
        &.logo {
          max-width: 317px;
        }
      }

      .navlinks {
        .navlink {
          font-size: 18px;

          &:hover {
            color: #4b4b4b;
          }
        }
      }
    }

    // Main Content
    .mainContent {
      p {
        font-size: 43px;
      }
    }

    // Download Buttons
    .downloadButtons {
      .downloadButton {
        font-size: 35px;

        .lottie {
          width: 150px;
          height: 150px;
          bottom: 24px;
        }

        &::before,
        &::after {
          width: 14px;
          height: 14px;
        }

        &::before {
          right: 24px;
        }

        &::after {
          right: 48px;
        }
      }
    }
  }

  @media (max-width: 1380px) {
    // Download Buttons
    .downloadButtons {
      .downloadButton {
        font-size: 25px;

        .lottie {
          width: 100px;
          height: 100px;
          bottom: 24px;
        }

        &::before,
        &::after {
          width: 10px;
          height: 10px;
        }

        &::before {
          right: 24px;
        }

        &::after {
          right: 40px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    overflow: hidden;
    max-height: 43px;

    &.opened {
      max-height: 10000px;
      transition: all 1.56s ease-in-out;
    }
  }

  @media (max-width: 600px) {
    // Main Content
    .mainContent {
      p {
        font-size: 25px;
      }
    }

    // Download Buttons
    .downloadButtons {
      grid-template-columns: 1fr;

      .downloadButton {
        &.website {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    max-height: 0;

    .topBar {
      display: none;
    }
  }
}
