// import Style Files
@import "../../styles/vars";

.SelectOptions {
  margin-top: 30px;

  .option {
    padding: 14px 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    border-radius: 8px;

    .optionIcon {
      img {
        width: 34px;
        height: 34px;
      }
    }

    .optionText {
      h3 {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: #3d3d3d;
      }

      p {
        margin: 2px 0 0;
        font-size: 12px;
        color: #afafaf;
        font-weight: 100;
      }
    }

    &:active {
      background: #f5f5f5;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
