// import Style Files
@import "../../styles/vars";

.Avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #cecdb2;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .charachter {
    width: 64px;
  }
}
