@import "../../../styles/vars";

.RightSide {
  height: calc(100vh - 80px);
  background-color: $primary-color;
  border-radius: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  // Illustrations
  .illustrations {
    overflow: hidden;
    border-radius: 40px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .grayIllustration {
    position: absolute;
    top: -130px;
    left: -130px;
    width: 180px;
    height: 180px;
    z-index: 1;
    transition: all 0.3s ease-out;

    &.enter {
      top: -70px;
      left: -70px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .greenIllustration {
    position: absolute;
    top: -130px;
    right: -130px;
    width: 180px;
    height: 180px;
    z-index: 2;
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;

    &.enter {
      top: -40px;
      right: -20px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .thirdIllustration {
    position: absolute;
    bottom: -200px;
    left: 0;
    z-index: 3;
    transition: all 0.3s ease-out;
    transition-delay: 0.6s;

    &.enter {
      bottom: -100px;
      left: 0;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  // Logo
  .logo {
    width: 60px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    max-width: 88%;
    width: -webkit-fill-available;
  }

  // Header
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      h1 {
        margin-bottom: 0;
        color: $text-color;
      }

      p {
        margin: 0;
        color: #00000069;
      }
    }
  }

  // Form
  .form {
    margin-top: 20px;
  }

  @media (min-width: 1600px) {
    .logo {
      width: 80px;
    }

    .container {
      max-width: 750px;
    }
  }

  @media (max-width: 500px) {
    padding: 70px 0;
  }
}
