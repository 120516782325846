@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Secular-One";
  font-weight: 400;
  src: url("../assets/fonts/SecularOne-Regular.ttf");
}
