@import "../../styles/vars";

.Home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  min-height: calc(100vh - 80px);

  .topBar {
    display: none;
    margin-bottom: -80px;
    position: relative;
    z-index: 9;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    gap: 20px;
    
    .topBar {
      display: block;
    }
  }

  @media (max-width: 560px) {
    gap: 40px;
  }
}
