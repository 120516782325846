@import "../../styles/vars";

.aboutPopup {
  position: absolute;
  top: 40px;
  right: -40px;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 20px;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.068);
  z-index: 1;
  flex-direction: column;
  gap: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 73px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  .me {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    object-position: top;
  }

  p {
    color: #707070;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }

  .social {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    .github {
      color: #000;
    }

    .linkedin {
      color: #0077b5;
    }

    .twitter {
      color: #1da1f2;
    }
  }

  .BuyMeACoffee {
    height: 60px;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  @media (max-width: 1380px) {
    right: -100px;
    &::before {
      right: 130px;
    }
  }

  @media (max-width: 1280px) {
    right: unset;
    left: calc(50% - 95px);
    width: 280px;

    &::before {
      right: 230px;
      top: -8px;
    }
  }
}
