@import "./vars";

body {
  background-color: #f1f1f1;
  padding: 40px;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0;

  @media (max-width: 1280px) {
    padding: 20px;
  }
}

.country {
  img {
    width: 30px;
    height: 20px;
    object-fit: cover;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: -4px;
  }
}

.entry-content {
  margin-top: 30px;
  padding: 20px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.04);
  border-radius: 36px;
  color: #747474;

  h1 {
    font-family: "Secular-One";
    color: #4b4b4b;
    letter-spacing: 1.5px;
  }

  a {
    color: $secondary-color;
  }
}
