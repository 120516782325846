@import "../../styles/vars";

.Form {
  background-color: $secondary-color;
  width: 100%;
  padding: 32px;
  border-radius: 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 8;

  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .input {
      input {
        &.inputText {
          width: 100%;
          padding: 12px 16px;
          height: 51px;
          box-sizing: border-box;
          background: none;
          border: 2px solid #2a7264;
          border-radius: 10px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          color: #fff;

          &::placeholder {
            color: #fff;
          }
        }
      }

      :global {
        .css-13cymwt-control,
        .css-t3ipsp-control {
          background: none;
          border: 2px solid #2a7264 !important;
          outline: none !important;
          border-radius: 10px;
          height: 51px;
        }

        .css-19bb58m,
        .css-1cfo1cf {
          color: #fff !important;
        }

        .css-t3ipsp-control,
        .css-1jqq78o-placeholder,
        .css-1dimb5e-singleValue {
          font-size: 14px;
          color: #fff !important;
        }

        .css-166bipr-Input {
          input {
            color: #fff !important;

            &::placeholder {
              color: #fff !important;
            }
          }
        }

        .css-15lsz6c-indicatorContainer,
        .css-1xc3v61-indicatorContainer {
          svg {
            color: #fff !important;
          }
        }

        .css-1xc3v61-indicatorContainer {
          &:hover {
            color: #fff !important;
          }
        }

        .css-c3incm-MenuList {
          font-size: 14px;
        }
      }
    }

    .button {
      button {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        color: #fff;
        background: #2a7264;
        border: 2px solid #2a7264;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        cursor: pointer;
        font-size: 16px;

        &:active {
          background-color: #225c50;
          border-color: #225c50;
        }
      }
    }
  }

  @media (max-width: 480px) {
    form {
      grid-template-columns: 1fr;
    }
  }
}
