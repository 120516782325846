@import "../../styles/vars";

.TopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    &.logo {
      max-width: 217px;
      max-height: 58px;
      cursor: pointer;
    }

    &.icon {
      max-width: 217px;
      max-height: 58px;
      cursor: pointer;
      display: none;
    }
  }

  .navlinks {
    display: flex;
    align-items: center;
    gap: 20px;

    .links {
      display: flex;
      gap: 20px;
    }

    .navlink {
      display: flex;
      font-weight: 500;
      color: #8e8e8e;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      font-size: 16px;

      &.bars {
        display: none;
      }

      &:hover {
        color: #4b4b4b;
      }
    }

    .socialLinks {
      display: flex;
      gap: 20px;
    }

    .aboutWrapper {
      position: relative;
    }
  }

  @media (max-width: 1360px) {
    img {
      &.logo {
        display: none;
      }

      &.icon {
        display: block;
      }
    }
  }

  @media (max-width: 1280px) {
    .bars {
      display: block !important;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 490px) {
    .navlinks {
      flex-direction: column;
      gap: 30px;
    }
  }
}
